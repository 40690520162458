import React, { Component } from "react";
import FranchiseServices from "services/franchises.services.js";
import UserServices from "services/users.services.js";
import OpportunityServices from "services/franchise-crm/opportunities.services";
import ContactServices from "services/franchise-crm/contacts.services";
import ProjectsServices from "services/franchise-projects/projects.services";
import InvoicesServices from "services/franchise-billing/invoices.services";
import UserContext from "providers/UserProvider";
import { FranchiseSingle, Grid } from "react-library-sm";
import moment from "moment";
import momentTz from "moment-timezone";

class FranchiseSingleComponent extends Component {
  constructor(props) {
    super(props);
    this.franchiseService = new FranchiseServices();
    this.userService = new UserServices();
    this.opportunityService = new OpportunityServices();
    this.projectService = new ProjectsServices();
    this.contactService = new ContactServices();
    this.invoiceService = new InvoicesServices();
    this.state = {
      franchise: {},
      opportunities: [],
      projects: [],
      taxes: [],
      year: moment().format("YYYY"),
      yearsDownloaded: [],
      compareInvoices: [],
      totals: {},
      currentYear: "",
      previousResponse: null,
    };
    // console.log("this.userService", this.userService);
  }

  componentDidMount() {
    /* Datos de franquicia */
    this.franchiseService
      .getFranchise(this.props.match.params.id)
      .then((response) => {
        // console.error("response getFranchise", response);
        this.setState({
          franchise: response.data.franchise,
          taxes: response.data.taxes,
        });
      })
      .catch((err) => {
        // console.error("err getFranchise", err);
      });

    /* Oportunidades */
    this.opportunityService
      .getOpportunities(this.props.match.params.id)
      .then((response) => {
        // console.error("response getOpportunities", response);
        let _opportunities = [...this.state.opportunities];
        _opportunities = [...response.data]
          .filter((opp) => opp.client && opp.client.length >= 1)
          .map((opp) => {
            return { ...opp, client: opp.client[0].name };
          });
        this.setState({ opportunities: _opportunities });
      });
    this.projectService
      .getProjects(this.props.match.params.id)
      .then((response) => {
        // console.log("response getProjects", response);
        this.setState({ projects: [...response.data] });
      });
    this.contactService
      .getContacts(this.props.match.params.id)
      .then((response) => {
        // console.log("response getContacts", response);
        this.setState({ contacts: [...response.data] });
      });
  }

  static contextType = UserContext;

  addUserToFranchise = (data) => {
    // console.log('createUser data en delivery', data);

    this.userService
      .createUsers(data.users, this.state.franchise)
      .then((response) => {
        // console.log('response', response);
        this.setState({ franchise: { ...response.data } });
      })
      .catch((err) => console.log(err));
  };

  editUser = (data) => {
    // console.log("editUser data en delivery", data);

    this.userService
      .putUser(data.users[0])
      .then((response) => {
        // console.log("response", response);
        let users = [...this.state.franchise.users];
        users.some((user, index) => {
          if (user._id === data.users[0]._id) {
            users[index] = data.users[0];
            return true;
          } else return false;
        });
        this.setState({ franchise: { ...this.state.franchise, users } });
      })
      .catch((err) => console.log(err));
  };
  editFranchise = (data) => {
    // console.log("data en editfranchise", data);
    this.franchiseService
      .putFranchise({
        ...data,
        stripe: this.state.franchise.users[0],
        certificate: this.state.franchise.billingInfo.certificate,
      })
      .then((response) => {
        this.setState({ franchise: { ...response.data } });
      });
  };

  unflatten = (data) => {
    const result = {};
    for (const i in data) {
      const keys = i.split("_");
      keys.reduce(function (r, e, j) {
        return (
          r[e] ||
          (r[e] = isNaN(Number(keys[j + 1]))
            ? keys.length - 1 === j
              ? data[i]
              : {}
            : [])
        );
      }, result);
    }
    return result;
  };

  handleCreateContact = (data) => {
    this.contactService
      .createContact(data, this.props.match.params.id)
      .then((response) => {
        // console.log('response en create contact list', response);
        let contacts = [...this.state.contacts];
        contacts = contacts.concat(response);
        this.setState({ contacts });
      })
      .catch((err) => {
        // console.log("err create contact", err.response);
      });
  };

  handleImportContacts = (data) => {
    data.forEach((dat) => {
      const auxContact = this.unflatten(dat);

      const finalContact = {
        ...auxContact,
        people: [],
        franchise: this.props.match.params.id,
      };
      this.contactService
        .addContact(finalContact)
        .then((response) => {
          finalContact._id = response.data._id;
          let personFinalContact = [...auxContact.people];
          const reqPersonFinalContact = Promise.all(
            (personFinalContact = personFinalContact.map((contact) => {
              contact.client = finalContact._id;
              contact.franchise = this.props.match.params.id;
              return this.contactService
                .addPersonOfContact(contact)
                .then((responsePerson) => {
                  this.state.contacts.push({
                    ...contact,
                    _id: response.data._id,
                  });
                  finalContact.people.push(responsePerson.data._id);
                })
                .catch((err) => {
                  // console.log("err add person of contact", err);
                });
            }))
          );

          reqPersonFinalContact
            .then((x) => {
              return this.contactService.updateContact(finalContact);
            })
            .then((response) => {
              const contacts = [...this.state.contacts];
              contacts.push(finalContact);
              this.setState({ contacts });
              // console.log(response);
            })
            .catch((err) => {
              // console.log("err update contact", err);
            });
        })
        .catch((err) => {
          // console.log("err add contact", err);
        });
    });
  };

  getInvoicesData = () => {
    console.log("test", this.state.yearsDownloaded, this.state.year);
    if (this.state.year !== this.state.currentYear) {
      return this.invoiceService
        .getInvoices(this.props.match.params.id, this.state.year)
        .then((response) => {
          if (response.data.dashboard !== undefined) {
            let invoices = response.data.invoices;

            invoices = invoices.map((invoice) => ({
              ...invoice,
              date: momentTz(invoice.date)
                .tz("Europe/Madrid")
                .format("YYYY-MM-DDTHH:mm:ss"),
            }));
            this.setState({
              previousResponse: {
                ...response,
                data: { ...response.data, invoices },
              },
            });

            console.log("invoices from", this.state.year, invoices);

            invoices = invoices.map((invoice) => ({
              ...invoice,
              date: momentTz(invoice.date)
                .tz("Europe/Madrid")
                .format("YYYY-MM-DDTHH:mm:ss"),
              dateCategory: `${this.state.year}_${invoice.category}`,
            }));
            if (!this.state.yearsDownloaded.includes(this.state.year)) {
              this.setState({
                compareInvoices: [...this.state.compareInvoices, ...invoices],
                yearsDownloaded: [
                  ...this.state.yearsDownloaded,
                  this.state.year,
                ],
                totals: {
                  ...this.state.totals,
                  [`total_${this.state.year}`]: invoices.reduce(
                    (final, inv) => inv.subtotal + final,
                    0
                  ),
                },
              });
            } else {
              this.setState({
                totals: {
                  ...this.state.totals,
                  [`total_${this.state.year}`]: invoices.reduce(
                    (final, inv) => inv.subtotal + final,
                    0
                  ),
                },
              });
            }

            return { ...response, data: { ...response.data, invoices } };
          } else {
            if (this.state.year === "2025") {
              this.handleChangeYear("2024");
              return this.invoiceService.getInvoices(
                this.props.match.params.id,
                "2024"
              );
            }
          }
        })
        .finally(() => {
          this.setState({ currentYear: this.state.year });
        });
    }
    return Promise.resolve(this.state.previousResponse);
  };

  handleChangeYear = (year) => {
    console.log("test year");
    this.setState({ year });
  };

  handleDownloadCompareYear = (year) => {
    this.invoiceService
      .getInvoices(this.props.match.params.id, year)
      .then((response) => {
        let invoices = response.data.invoices;
        console.log("invoices from aaa", year, invoices);

        invoices = invoices.map((invoice) => ({
          ...invoice,
          date: momentTz(invoice.date)
            .tz("Europe/Madrid")
            .format("YYYY-MM-DDTHH:mm:ss"),
          dateCategory: `${year}_${invoice.category}`,
        }));

        this.setState({
          compareInvoices: [...this.state.compareInvoices, ...invoices],
          totals: {
            ...this.state.totals,
            [`total_${year}`]: invoices.reduce(
              (final, inv) => inv.subtotal + final,
              0
            ),
          },
        });

        // const compareDashboard = _.cloneDeep(this.state.compareDashboard);

        // console.log("final", year, compareDashboard);
        // this.setState({ compareDashboard: compareDashboard });
        // this.setState({
        //   franchises: responseParsed.franchises,
        //   taxes: responseParsed.taxes,
        //   dashboard: { ...responseParsed.dashboard },
        // });
      })
      .catch((err) => {
        console.log("err get franchises", err);
      });
  };

  handleSelectYears = (years) => {
    console.log("years to download", years);
    const stateYears = [...this.state.yearsDownloaded];
    const yearsToDownload = years.filter((year) => !stateYears.includes(year));

    yearsToDownload.forEach((year) => {
      this.handleDownloadCompareYear(year);
      stateYears.push(year);
    });

    this.setState({ yearsDownloaded: stateYears, compareYears: years });
    // const downloadYears = years.filter((year) => {
    //   console.log(
    //     "year a comprobar",
    //     year,
    //     stateYears,
    //     !stateYears.includes(year)
    //   );
    //   return !stateYears.includes(year);
    // });
    // console.log("solo van a comprobar estos años", downloadYears);
    // downloadYears.forEach((year) => {
    //   console.log("year to check", year);
    //   this.handleDownloadCompareYear(year);
    // });
    // this.setState({ compareYears: years });
  };

  getInvoicePdf = (id) => {
    console.log(id);
  };

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <Grid item md={12}>
          {console.log("this.state.franchise", this.state.franchise)}
          {this.state.franchise ? (
            <FranchiseSingle
              getInvoicePdf={this.getInvoicePdf}
              columns={[
                this.context.translation.dataTables.opportunities.columns,
                this.context.translation.dataTables.projects.columns,
                this.context.translation.dataTables.tasks.columns,
                this.context.translation.dataTables.franchise_invoices.columns,
              ]}
              title={[
                this.context.translation.dataTables.opportunities.title,
                this.context.translation.dataTables.projects.title,
                this.context.translation.dataTables.tasks.title,
                this.context.translation.dataTables.franchise_invoices.title,
              ]}
              options={{
                textLabels: this.context.translation.dataTables.textLabels,
              }}
              franchise={this.state.franchise}
              editFranchise={this.editFranchise}
              createUser={this.addUserToFranchise}
              editUser={this.editUser}
              deleteUser={this.deleteUser}
              language={this.context.language}
              opportunities={this.state.opportunities}
              projects={this.state.projects}
              contacts={this.state.contacts}
              handleImportContacts={this.handleImportContacts}
              handleCreateContact={this.handleCreateContact}
              taxes={this.state.taxes}
              getInvoicesData={this.getInvoicesData}
              year={this.state.year}
              handleChangeYear={this.handleChangeYear}
              handleSelectYears={this.handleSelectYears}
              compareInvoices={this.state.compareInvoices}
              totals={this.state.totals}
            />
          ) : (
            <h1>Cargando...</h1>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default FranchiseSingleComponent;
